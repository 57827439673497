/*
interface EntityData {
   public id: string;
   public set: string,
}


// don't declare two properties,
// just create an interface
interface Entity extends EntityData { }
*/

/*
class Entity {
    // destruct data
    constructor({ id, set }: EntityData) {
        this.id = id;
        this.set = set;
    }

    toObject(): EntityData {
        // destruct all own properties
        // (not methods in prototype)
        return { ...this };
    }
}
*/

/*

export class Customer implements ICustomer {
    constructor(public Id: number,
        public FirstName: string,
        public LastName: string) { }


    public getId() { return this.Id; }
    public setId(Id: number) { this.Id = Id; }
    public getFirstName() { return this.FirstName; }
    public setFirstName(FirstName: string) { this.FirstName = FirstName; }
    public getLastName() { return this.LastName; }
    public setLastName(LastName: string) { this.LastName = LastName; }
}
*/

export namespace DRLT.Web.Entity {

    export class AdviceInfo {
        constructor() {
            this.RecId = 0;
            this.AdviceNo = '';
            this.Name='';
            this.Tel = '';
            this.Email = '';
            this.Title = '';
            this.Advice = '';
            this.ReceiveMessage = false;
            this.CreatedDateTime = new Date();
            this.Solution = '';
            this.RepliedDateTime = new Date();
            this.RepliedBy = '';

            //this.RecId = 0;
            //this.AdviceNo = '';
            //this.Name='';
            //this.Tel = '';
            //this.Email = '';
            //this.Title = '';
            //this.Advice = '';
            //this.ReceiveMessage = false;
            //this.CreatedDateTime = new Date();
            //this.Solution = '';
            //this.RepliedDateTime = new Date();
            //this.RepliedBy = '';
        }

        public RecId: number;
        public AdviceNo: string;
        public Name: string;
        public Tel: string;
        public Email: string;
        public Title: string;
        public Advice: string;
        public ReceiveMessage: boolean;
        public CreatedDateTime: any;
        public Solution: string;
        public RepliedDateTime: any;
        public RepliedBy: string;

        /*
        public RecId: number = 0;
        public AdviceNo: string = '';
        public Name: string = '';
        public Tel: string = '';
        public Email: string = '';
        public Title: string = '';
        public Advice: string = '';
        public ReceiveMessage: boolean = false;
        public CreatedDateTime: any = new Date();
        public Solution: string = '';
        public RepliedDateTime: any = new Date();
        public RepliedBy: string = '';
        */
    }




}