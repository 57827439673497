import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebHeader = _resolveComponent("WebHeader")!
  const _component_HeroBoard = _resolveComponent("HeroBoard")!
  const _component_FeaturedServices = _resolveComponent("FeaturedServices")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_OurServices = _resolveComponent("OurServices")!
  const _component_ContactUs = _resolveComponent("ContactUs")!
  const _component_WebFooter = _resolveComponent("WebFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WebHeader, { msg: "WebHeader" }),
    _createVNode(_component_HeroBoard, { msg: "HeroBoard" }),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_FeaturedServices, { msg: "FeaturedServices" }),
      _createVNode(_component_AboutUs, { msg: "AboutUs" }),
      _createVNode(_component_OurServices, { msg: "OurServices" }),
      _createVNode(_component_ContactUs, { msg: "ContactUS" }),
      _createVNode(_component_WebFooter, { msg: "WebFooter" })
    ])
  ], 64))
}