
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import useTable from './plugins/vxe-table'

import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'

//import 'vxe-table/lib/index.css';
//import 'vxe-table/styles/index.scss';
import 'vxe-table/lib/style.css'

import Header from 'vxe-table'
import Column from 'vxe-table'
import Table from 'vxe-table'

import { Form, FormItem,Input, Checkbox, Button, Textarea} from 'vxe-table'

import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

// ������صķ�ʽĬ���ǲ������ʻ��ģ��Զ�����ʻ���Ҫ���н���ռλ�� '{0}'�����磺
VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

//const AdminLogin = { render() { return '123' } }

const mainApp = createApp(App);

//mainApp.config.globalProperties.$cookies = VueCookies;

//mainApp.config.globalProperties.$comm = comm;

//const addJs = async () => {
//    await import('./assets/js/index.js')
//}

mainApp.use(router);

//mainApp.use(useTable);

mainApp.use(VXETable)
    .use(Header)
    .use(Column)
    .use(Table)
    .use(Form)
    .use(FormItem)
    .use(Input)
    .use(Checkbox)
    .use(Button)
    .use(Textarea);

mainApp.mount('#app');

