
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'AdminTop',

        mounted()
        {
            
        },

        methods: {
             LoginUsers(): void {
                
               
                
            }


        },
    });
