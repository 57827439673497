
    import { defineComponent } from 'vue';
    //import * as WebPageLogEntity from "@/entity/WebPageLogInfo";

    export default defineComponent({
        name: 'App',
        created() {

            //收集日志信息
            //let webPageLogEntity = new WebPageLogEntity.DRLT.Web.Entity.WebPageLogInfo(); 
            //webPageLogEntity.Browser = Request.he

            //发送访问日志
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" } //,
                //body: JSON.stringify(this.$data)
            };

            /*
            fetch("/odata/WebPageLogs", requestOptions)
                .then(r => r.json()); 
            */

            async function sendLog() {
                try {
                    const response: Response = await fetch('/odata/WebPageLogs', requestOptions);
                    if (response.ok) {
                        //const text: string = await response.text();
                        //console.log(text);
                    } else {
                        //console.error('Error:', response.status);
                    }
                } catch (error) {
                    //console.error('Error:', error);
                }
            }

            sendLog();


        },
        components: {
        }
    });
