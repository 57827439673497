
    import { defineComponent } from 'vue';
    import { useCookies } from "vue3-cookies";
    import router from '@/router';

    export default defineComponent({
        name: 'AdminLogin',

        data(){
            return {
              UserName:"",
              UserPwd: "",
              Message:"",
              BasicUrl:process.env.VUE_APP_BASE_URL
            }
          },
       
        setup() {
            const { cookies } = useCookies();
            return { cookies };
        },

        mounted()
        {
            let button = document.getElementById('input_Login');
            button?.addEventListener('click', this.LoginUsers);
            
            //button?.addEventListener('click', ()=>{alert(123);});      
        },

        methods: {
             LoginUsers(): void {
                
                //var jsondata = null;

                // check value
                if (this.UserName.length == 0) {
                    this.Message = "请输入用户名。";
                    document.getElementById("input_UserName")?.focus();
                    return;
                }

                if (this.UserPwd.length == 0) {
                    this.Message = "请输入密码。";
                    document.getElementById("input_UserPwd")?.focus();
                    return;
                }

                //state.test = async () => {return await fetch("https://api.npms.io/v2/search?q=vue"); };

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ LoginName: this.UserName, LoginPwd: this.UserPwd })
                };

                fetch("/odata/Users/PostLoginUser", requestOptions)
                    .then(r => r.json())
                    .then(json => {
 
                        var resultJson = JSON.parse(JSON.stringify(json));
                        if (resultJson.code == "0") {

                            // auth code
                            if (this.cookies.isKey('drlt_authcode')) {
                                this.cookies.remove('drlt_authcode');
                            }

                            this.cookies.set('drlt_authcode', resultJson.authCode);

                            // menu list
                            if (this.cookies.isKey('drlt_menulist')) {
                                this.cookies.remove('drlt_menulist')
                            }

                            this.Message = "";
                            router.push({ name: "adminHome" });

                        }
                        else {
                            this.Message = "登录认证失败。";
                        }


                    });

                /*
                .then((response) => { 
                       
                   //do something awesome that makes the world a better place
                    




                });
                */
                

                //alert(JSON.stringify(jsondata));

               
                
            }


        },
    });
