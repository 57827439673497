
         
    import { defineComponent } from 'vue';
    //import { useCookies } from "vue3-cookies";
    import * as adviceEntity from "../entity/AdviceInfo";

    export default defineComponent({
        name: 'ContactUs',

        data() {
            //let that = this;
            //const test = new AdviceInfo();
            //let obj: DRLT_Web_Entity.AdviceInfo = new DRLT_Web_Entity.AdviceInfo();

            let t = new adviceEntity.DRLT.Web.Entity.AdviceInfo(); // shapes.Shapes?
            //alert(JSON.stringify(t));

            //return Json(new { msg = message,data = string.Format("{{\"arry\":{0},\"code\":\"{1}\"}}", str,"md5"),success = flag });
            
            var entityJson =  JSON.parse(JSON.stringify(t));
            entityJson.Message = '';
            return entityJson;

            //return entityJson;

            /*
            return {
                UserName: "",
                UserPwd: "",
                Message: "",
                BasicUrl: process.env.VUE_APP_BASE_URL
            }
            */
        },

        mounted() {
            let button = document.getElementById('btn_SubmitAdvice');
            button?.addEventListener('click', this.SubmitAdvice);

           

            //button?.addEventListener('click', ()=>{alert(123);});      
        },

        methods: {
            SubmitAdvice(): void {

                //alert(this.Name);
                
                //var jsondata = null;

                // check value
                if (this.Title.length == 0) {
                    this.Message = "请输入建议标题。";
                    document.getElementById("title")?.focus();
                    return;
                }

                if (this.Advice.length == 0) {
                    this.Message = "请输入建议内容。";
                    document.getElementById("Advice")?.focus();
                    return;
                }

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(this.$data)
                };

       
                fetch("/odata/Advices", requestOptions)
                    .then(r => r.json())
                    .then(json => {

                        var resultJson = JSON.parse(JSON.stringify(json));
                        if (resultJson.code == "0") {

                            // auth code
                            //if (this.cookies.isKey('drlt_authcode')) {
                            //    this.cookies.remove('drlt_authcode');
                            //}

                            //this.cookies.set('drlt_authcode', resultJson.authCode);
                          
                            Object.assign(this.$data, this.$options.data())

                            this.Message = "建议发送成功";
                        }
                        else {
                            this.Message = "建议发送失败。";
                        }


                    }); 

            }


        },
    });
