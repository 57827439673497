
    import { defineComponent } from 'vue';
    import WebHeader from '@/views/WebHeader.vue';
    import HeroBoard from '@/views/HeroBoard.vue';
    import FeaturedServices from '@/views/FeaturedServices.vue';
    import AboutUs from '@/views/AboutUs.vue';
    import OurServices from '@/views/OurServices.vue';
    import ContactUs from '@/views/ContactUs.vue';
    import WebFooter from '@/views/WebFooter.vue';

    export default defineComponent({
        name: 'WebView',
        components: {
            WebHeader,
            HeroBoard,
            FeaturedServices,
            AboutUs,
            OurServices,
            ContactUs,
            WebFooter
        },
       mounted() {
            const recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute(
                "src",
                "assets/js/main.js");
            document.body.appendChild(recaptchaScript);
        }
    });
