import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//import { createRouter, createWebHashHistory } from 'vue-router';
import WebView from '@/views/WebView.vue'
import AdminLogin from '@/admin/AdminLogin.vue';
import AdminHome from '@/admin/AdminHome.vue';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'web',
        component: WebView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminLogin
    },
    {
        path: '/adminHome',
        name: 'adminHome',
        component: AdminHome,
        beforeEnter: requireAuth,
        children: [
            {
                path: 'AdviceList',
                name: 'AdviceList',
                components: { 
                    adminRouterView: () => import('@/admin/AdviceList.vue')
                },
                beforeEnter: requireAuth,
            },
            {
                path: 'WebStatistic',
                name: 'WebStatistic',
                components: {
                    adminRouterView: () => import('@/admin/WebStatistic.vue')
                },
                beforeEnter: requireAuth,
            },
            {
                path: 'SystemSetting',
                name: 'SystemSetting',
                components: {
                    adminRouterView: () => import('@/admin/SystemSetting.vue')
                },
                beforeEnter: requireAuth,
            },
            {
                path: 'test',
                name: 'test',
                component: AdminLogin,
            }
        ]
    }
]

const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(process.env.BASE_URL),
    routes
})

function requireAuth(to: any, from: any, next: any) {
    //TODO:���Խ�userAuthorization��ΪToken�ŵ�UserInfoʵ���ϡ� UserInfo��Ϊȫ�ֱ�������
    if (cookies.isKey('drlt_authcode')) {

        //��֤Authcode
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": cookies.get('drlt_authcode') }
        };

        fetch("/odata/Users/ValidateAuthCode", requestOptions)
            .then(r => r.json())
            .then(json => {

                const resultJson = JSON.parse(JSON.stringify(json));
                if (resultJson.code == "0") {
                    next();
                }
                else {
                    next("/admin");
                }

            });
    } else {
        //û��Ȩ��
        next("/admin");
    }
}

export default router
